import Button from "./button";
import {useState} from "react";
import useBL from "../hook/useBL";
import config from "../config.json";
import ErrorModal from "./error-modal";

export default function RedeemPointsButton({point_of_sales, inline = false}) {

  const [cadoozing, setCadoozing] = useState(false);
  const [errors, setErrors] = useState([]);
  const bl = useBL();

  const cadooz = (point_of_sales) => {
    if (!cadoozing) {
      setCadoozing(true);
      bl.get_cadooz_code({point_of_sale_id: point_of_sales.id})
        .then(code => window.open(config.CADOOZ_URL + '?code=' + code, '_blank'))
        .catch(errors => setErrors(errors))
        .finally(() => setCadoozing(false));
    }
  };

  return (
    <>
      <Button
        inline={inline} text={cadoozing ? 'Bitte warten…' : 'Punkte einlösen'} color="primary"
        onClick={() => cadooz(point_of_sales)}
      />
      <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
    </>
  );

}
