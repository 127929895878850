import './line-item.scss';
import Button from "./button";
import {Loader, X} from "./icons";
import AmountSelection from "./amount-selection";
import Price from "./price";
import {useEffect, useState} from "react";
import useCRUD from "../hook/useCRUD";
import IconAndText from "./icon-and-text";
import ErrorModal from "./error-modal";

export default function LineItem({lineItem, setAmount}) {
  
  const product = lineItem.product ?? null;
  const promotion = lineItem.promotion ?? null;
  const crud = useCRUD();
  const [placeholderImage, setPlaceholderImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (product && !product.image && product.product_type?.placeholder_id) {
      setIsLoading(true);
      crud.data.read({entity: 'file', id: product.product_type?.placeholder_id})
        .then(placeholderResult => setPlaceholderImage(placeholderResult))
        .catch(errors => setErrors(errors))
        .finally(() => setIsLoading(false));
      setTimeout(() => setIsLoading(false), 5000);
    }
  }, [crud.data, product]);

  return (
    <div className="LineItem">
      <div className="image">
        {isLoading && <IconAndText icon={<Loader/>} text="Laden…" className="mt-1-75"/>}
        {
          !isLoading &&
          <>
            {product?.image && <img src={product.image.url} alt=""/>}
            {product && !product.image && placeholderImage && <img src={placeholderImage.url} alt=""/>}
            {promotion?.image && <img src={promotion.image.url} alt=""/>}
          </>
        }
      </div>
      <div className="text">
        {
          promotion &&
          <>
            <div className="title">
              <div className="headline">{promotion.headline}</div>
            </div>
            {
              promotion.price &&
              <>
                <div className="unit-price hidden-up-to-lg">
                  <Price amount={promotion.price} info="UVP" align="left"/>
                  <div className="description">{promotion.headline}</div>
                </div>
                <div className="total-price">
                  <Price amount={lineItem.amount * promotion.price} info="UVP" align="left"/>
                  <div className="description hidden-lg-up">{lineItem.amount} &times; {promotion.headline}</div>
                </div>
                {
                  setAmount &&
                  <AmountSelection amount={lineItem.amount} min={0} onChange={amount => setAmount(lineItem, amount)}/>
                }
                {!setAmount && <div className="amount hidden-up-to-lg">&times; {lineItem.amount}</div>}
              </>
            }
          </>
        }
        {
          product &&
          <>
            <div className="title">
              <div className="brand">{product.brand?.name}</div>
              <div className="name">{product.name}</div>
            </div>
            <div className="unit-price hidden-up-to-lg">
              <Price amount={product.price} info={product.product_type?.price_info} align="left"/>
              <div className="description">{product.description}</div>
            </div>
            <div className="total-price">
              <Price amount={lineItem.amount * product.price} info={product.product_type?.price_info} align="left"/>
              <div className="description hidden-lg-up">{lineItem.amount} &times; {product.description}</div>
            </div>
            {
              setAmount &&
              <AmountSelection
                amount={lineItem.amount} min={0} onChange={amount => setAmount(lineItem, amount)}
                freeGood={parseInt(product?.free_good) === 1}
              />
            }
            {!setAmount && <div className="amount hidden-up-to-lg">&times; {lineItem.amount}</div>}
          </>
        }
      </div>
      {
        setAmount &&
        <div className={"action" + (parseInt(product?.free_good) === 1 ? " hidden" : "")}>
          <Button inverted icon={<X/>} onClick={() => setAmount(lineItem, 0)}/>
        </div>
      }
      <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
    </div>
  );

}
