import "./product-card.scss";
import AmountSelection from "./amount-selection";
import Button from "./button";
import {CircleCheck, Loader, ShoppingCartPlus as ShoppingCartPlusIcon} from "./icons";
import {useContext, useEffect, useState} from "react";
import {ShoppingCartContext} from "../context/shopping-cart-context";
import ButtonLink from "./button-link";
import Price from "./price";
import useCRUD from "../hook/useCRUD";
import ErrorModal from "./error-modal";
import IconAndText from "./icon-and-text";

export default function ProductCard({product}) {

  const shoppingCartContext = useContext(ShoppingCartContext);
  const [amount, setAmount] = useState(1);
  const [justAdded, setJustAdded] = useState(false);
  const crud = useCRUD();
  const [placeholderImage, setPlaceholderImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState([]);

  const addToCart = () => {
    shoppingCartContext.addToCart('product', product.id, amount);
    setJustAdded(true);
    setTimeout(() => setJustAdded(false), 2000);
    setAmount(1);
  };

  useEffect(() => {
    if (!product.image) {
      setIsLoading(true);
      crud.data.read({entity: 'file', id: product.product_type?.placeholder_id})
        .then(placeholderResult => setPlaceholderImage(placeholderResult))
        .catch(errors => setErrors(errors))
        .finally(() => setIsLoading(false));
      setTimeout(() => setIsLoading(false), 5000);
    }
  }, [crud.data, product.image, product.product_type?.placeholder_id]);

  return (
    <div className="ProductCard">
      <div className="tags">
        {product.is_top_seller !== '0' && <div><span>Top-Seller</span></div>}
        {product.is_new !== '0' && <div><span>Neu</span></div>}
        {product.is_out_of_stock !== '0' && <div className="danger"><span>z.Zt. nicht verfügbar</span></div>}
      </div>
      {isLoading && <IconAndText icon={<Loader/>} text="Laden…" className="mt-1-75"/>}
      {!isLoading && product.image && <img src={product.image.url} alt=""/>}
      {
        !isLoading && !product.image && product.product_type && placeholderImage &&
        <img src={placeholderImage.url} alt=""/>
      }
      <div className="brand">{product.brand && product.brand.name}</div>
      <div className="name">{product.name}</div>
      <Price className="mt-1" amount={product.price} info={product.product_type?.price_info}/>
      <div className="description">{product.description}</div>
      <AmountSelection amount={amount} onChange={setAmount} disabled={product.is_out_of_stock !== '0'}/>
      {
        !justAdded &&
        <Button
          text="In den Warenkorb" icon={<ShoppingCartPlusIcon/>} onClick={addToCart}
          disabled={product.is_out_of_stock !== '0'}
        />
      }
      {justAdded && <ButtonLink to="/warenkorb" text="Warenkorb anzeigen" icon={<CircleCheck/>} inverted/>}
      <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
    </div>
  );
}
