import "./footer.scss";
import Section from "./section";
import {NavLink, useLocation} from "react-router-dom";
import Nav from "./nav";
import Row from "./row";
import Widget from "./widget";
import NewsletterSubscription from "./newsletter-subscription";
import {useContext} from "react";
import {AuthContext} from "../context/auth-context";
import {useState} from "react";
import ContactForm from "./contact-form";
import useBL from "../hook/useBL";
import {PosContext} from "../context/pos-context";
import ErrorModal from "./error-modal";
import config from "../config.json";
import useClaims from "../hook/useClaims";
import useShowCredits from "../hook/useShowCredits";

export default function Footer() {

  const authContext = useContext(AuthContext);
  const posContext = useContext(PosContext);
  const [showContactForm, setShowContactForm] = useState(false);
  const [errors, setErrors] = useState([]);
  const bl = useBL();
  const claims = useClaims();
  const showCredits = useShowCredits();
  const location = useLocation();

  const cadooz = () => {
    if (authContext.authToken && posContext.activePos?.id && showCredits) {
      bl.get_cadooz_code({point_of_sale_id: posContext.activePos.id})
        .then(code => window.open(config.CADOOZ_URL + '?code=' + code, '_blank'))
        .catch(errors => setErrors(errors));
    }
  };

  return (
    <footer className="Footer">
      <Section background>
        {
          authContext.authToken &&
          <Row columns={{default: 1, md: 3}}>
            {
              location.pathname !== '/bonusclub' &&
              <Widget
                headline="Allgemeine Fragen" buttonText="Web-Formular" onClick={() => setShowContactForm(true)}
                buttonInverted
              >
                <p>Wenn es schnell gehen muss. Wir freuen uns von Ihnen zu hören.</p>
                <p>
                  Tel.: <a href="tel:+496518265127">+49 651 82 65–127</a><br/>
                  E-Mail: <a href="mailto:bestellung@landewyck.com">bestellung@landewyck.com</a>
                </p>
              </Widget>
            }
            {
              location.pathname === '/bonusclub' &&
              <Widget headline="Rückfragen zum Prämienshop">
                <p>Wenn es schnell gehen muss. Wir freuen uns von Ihnen zu hören.</p>
                <p>
                  Tel.: <a href="tel:+4940271482150">+49 40 271482150</a><br/>
                  E-Mail: <a href="mailto:landewyck@cadooz.de">landewyck@cadooz.de</a>
                </p>
              </Widget>
            }
          </Row>
        }
        {
          !authContext.authToken &&
          <Row columns={{default: 1, md: 3}}>
            <Widget headline="Neukunden" buttonText="Web-Formular" onClick={() => setShowContactForm(true)}>
              Sie haben Fragen zum Login, zu unseren Marken oder Produkten? Dann schreiben Sie uns eine E-Mail über das
              Web-Formular.
            </Widget>
            <Widget headline="Login" buttonText="Login" buttonTo="/anmelden" buttonInverted>
              Sie sind schon ein Teil der Landewyck Familie? Dann loggen Sie sich ein.
            </Widget>
          </Row>
        }
        <NewsletterSubscription/>
      </Section>
      <Section className="panel">
        <img src={process.env.PUBLIC_URL + '/landewyck-1847.svg'} alt="Landewyck 1847"/>
        <ul className="menu">
          <li>
            <div className="headline">Zentrale</div>
            <p>
              <strong>Büro</strong><br/>
              Tabak- und Zigarettenfabrik Heintz van Landewyck GmbH<br/>
              Niederkircher Str. 31<br/>
              D‑54294 Trier
            </p>
          </li>
          <li>
            <div className="headline">Direktlinks</div>
            <Nav uppercase>
              {
                (!claims.online_sales || claims.field_sales || claims.sales)
                && <NavLink to="produkte">Produkte</NavLink>
              }
              {
                !claims.sales
                && !claims.field_sales
                && !claims.online_sales
                && <NavLink to="aktionen">Aktionen</NavLink>
              }
              <NavLink to="downloads">Downloads</NavLink>
              {
                (
                  (!claims.sales && !claims.field_sales)
                  || claims.online_sales
                )
                && <NavLink to="support">Support</NavLink>
              }
              <a href="https://landewyck.com" target="_blank" rel="noreferrer">landewyck.com</a>
            </Nav>
          </li>
          <li>
            <div className="headline">Partner</div>
            <img src={process.env.PUBLIC_URL + '/dhl.svg'} alt="DHL"/>
            {
              config.CADOOZ_URL &&
              <img src={process.env.PUBLIC_URL + '/cadooz.png'} alt="Cadooz" onClick={cadooz}/>
            }
          </li>
        </ul>
      </Section>
      <Section background className="baseboard">
        © {(new Date()).getFullYear()} Landewyck Tobacco S.A.
        <Nav inline>
          <NavLink to="impressum">Impressum</NavLink>
          <NavLink to="nutzungs-und-geschaeftsbedingungen">Nutzungs- und Geschäftsbedingungen</NavLink>
          <NavLink to="datenschutz">Datenschutz</NavLink>
        </Nav>
      </Section>
      {showContactForm && <ContactForm onDismiss={() => setShowContactForm(false)}/>}
      <ErrorModal errors={errors} onDismiss={() => setErrors([])}/>
    </footer>
  );
}
