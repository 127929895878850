import config from "../config.json";
import {useContext, useMemo} from "react";
import {AuthContext} from "../context/auth-context";

export default function useBL(authToken) {

  const authContext = useContext(AuthContext);

  return useMemo(() => {

    const call = (remoteFunction, remoteArguments = {}) => {
      let url = config.BL_URL + '/' + remoteFunction.replaceAll('/', '.');
      return new Promise((resolve, reject) => {
        let body = {
          function: remoteFunction,
          parameters: remoteArguments
        };
        if (authToken || authContext.authToken) {
          body.auth = authToken || authContext.authToken;
        }
        fetch(
          url,
          {
            method: 'POST',
            body: JSON.stringify(body)
          })
          .then(response => {
            if (response.status !== 200) {
              reject([{message: 'Interner Fehler in der Business Logik.'}]);
            } else {
              response.json()
                .then(result => {
                  if ('errors' in result && result.errors.length > 0) {
                    result.errors.forEach(error => {
                      if (error.class === 'Exception') {
                        console.log(error);
                        error.message = 'Sie verwenden möglicherweise eine veraltete Version der Webseite. ' +
                          'Bitte laden Sie die Seite neu und versuchen Sie es erneut. Sollte das Problem ' +
                          'dauerhaft bestehen, wenden Sie sich bitte an unseren Support.';
                      }
                    });
                    reject(result.errors);
                  } else {
                    resolve(result.result);
                  }
                })
                .catch(() => {
                  return [{message: 'Fehler beim Verarbeiten der Antwort aus der Business Logik.'}];
                });
            }
          })
          .catch(error => {
            console.error(error);
            reject([{
              message: 'Die Verbindung zur Business Logik kann nicht hergestellt werden.'
            }]);
          });
      });
    };

    return {
      login: ({username_or_email, password}) => call('login', {username_or_email, password}),
      reset_password: ({token, password}) => call('reset_password', {token, password}),
      validate_password_reset_token: ({token}) => call('validate_password_reset_token', {token}),
      ensure_password_complexity: ({password}) => call('ensure_password_complexity', {password}),
      change_password: ({old_password, new_password}) => call('change_password', {old_password, new_password}),
      update_point_of_sale_abbreviation:
        ({id, new_abbreviation}) => call('update_point_of_sale_abbreviation', {id, new_abbreviation}),
      recover_password: ({username_or_email, url}) => call('recover_password', {username_or_email, url}),
      send_contact_form: (
        {email, message, customer_number, first_name, last_name, pos_name, street, postal_code, city, phone}
      ) => call(
        'send_contact_form',
        {email, message, customer_number, first_name, last_name, pos_name, street, postal_code, city, phone}
      ),
      order: ({point_of_sale_id, shopping_cart_id, wholesaler_id, custom_wholesaler}) =>
        call('order', {point_of_sale_id, shopping_cart_id, wholesaler_id, custom_wholesaler}),
      new_shopping_cart: () => call('new_shopping_cart'),
      get_shopping_cart: ({shopping_cart_id, point_of_sale_id}) =>
        call('get_shopping_cart', {shopping_cart_id, point_of_sale_id}),
      add_to_shopping_cart: ({shopping_cart_id, entity, entity_id, amount, point_of_sale_id}) =>
        call('add_to_shopping_cart', {shopping_cart_id, entity, entity_id, amount, point_of_sale_id}),
      add_promotion_to_shopping_cart: ({shopping_cart_id, promotion_id, amount, point_of_sale_id}) =>
        call('add_promotion_to_shopping_cart', {shopping_cart_id, promotion_id, amount, point_of_sale_id}),
      set_shopping_cart_amount: ({shopping_cart_id, entity, entity_id, amount, point_of_sale_id}) =>
        call('set_shopping_cart_amount', {shopping_cart_id, entity, entity_id, amount, point_of_sale_id}),
      remove_from_shopping_cart: ({shopping_cart_id, entity, entity_id, point_of_sale_id}) =>
        call('remove_from_shopping_cart', {shopping_cart_id, entity, entity_id, point_of_sale_id}),
      renew_auth_token: () => call('renew_auth_token'),
      get_cadooz_code: ({point_of_sale_id}) => call('get_cadooz_code', {point_of_sale_id}),
      get_credit_balance: () => call('get_credit_balance')
    };

  }, [authContext.authToken, authToken]);

}
